import { buildClasses } from "../utils/buildClasses";
import { RyeIcon } from "./RyeIcon";

export function RyeButton({
  text,
  icon,
  onClick = () => {},
  variant = "outlined",
  vibe = "ordinary",
  size = "md",
  shape = "rounded-rect",
  shadow = "none",
  disabled = false,
}: {
  text?: string;
  icon?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  size?: "sm" | "md" | "lg";
  shape?: "rounded-rect" | "fully-round";
  shadow?: "none" | "sm" | "md" | "lg";
  variant?: "filled" | "outlined" | "transparent";
  vibe?: "subdued" | "ordinary" | "primary" | "danger";
  disabled?: boolean;
}): JSX.Element {
  return (
    <button
      onClick={(e) => onClick(e)}
      className={buildClasses(
        // Size
        {
          switch: size,
          cases: new Map([
            [
              "sm",
              buildClasses(
                { if: text === undefined, then: "w-7", else: "px-2.5" },
                "h-7",
                "text-sm"
              ),
            ],
            [
              "md",
              buildClasses(
                { if: text === undefined, then: "w-9", else: "px-3" },
                "h-9",
                "text-md"
              ),
            ],
            [
              "lg",
              buildClasses(
                { if: text === undefined, then: "w-11", else: "px-3.5" },
                "h-11",
                "text-lg"
              ),
            ],
          ]),
        },
        // Shape
        {
          switch: shape,
          cases: new Map([
            ["rounded-rect", buildClasses("rounded-md")],
            ["fully-round", buildClasses("rounded-full")],
          ]),
        },
        // Shadow
        {
          switch: shadow,
          cases: new Map([
            ["none", buildClasses("shadow-none")],
            ["sm", buildClasses("shadow-sm")],
            ["md", buildClasses("shadow-md")],
            ["lg", buildClasses("shadow-lg")],
          ]),
        },
        // Variant & Vibe
        {
          switch: variant,
          cases: new Map([
            [
              "filled",
              buildClasses({
                switch: vibe,
                cases: new Map([
                  [
                    "subdued",
                    buildClasses(
                      "bg-on-surface-200",
                      "text-on-surface-600",
                      "enabled:hover:bg-on-surface-300"
                    ),
                  ],
                  [
                    "ordinary",
                    buildClasses(
                      "bg-on-surface-400",
                      "text-on-surface-50",
                      "enabled:hover:bg-on-surface-500"
                    ),
                  ],
                  [
                    "primary",
                    buildClasses(
                      "bg-purple",
                      "text-on-surface-50",
                      "enabled:hover:bg-purple-600"
                    ),
                  ],
                  [
                    "danger",
                    buildClasses(
                      "bg-red",
                      "text-on-surface-50",
                      "enabled:hover:bg-red-600"
                    ),
                  ],
                ]),
              }),
            ],
            [
              "outlined",
              buildClasses({
                switch: vibe,
                cases: new Map([
                  [
                    "subdued",
                    buildClasses(
                      "border",
                      "border-on-surface-200",
                      "text-on-surface-500",
                      "enabled:hover:bg-on-surface-100",
                      "enabled:hover:border-on-surface-300",
                      "enabled:hover:text-on-surface-600"
                    ),
                  ],
                  [
                    "ordinary",
                    buildClasses(
                      "border",
                      "border-on-surface-300",
                      "bg-on-surface-100",
                      "text-on-surface-600",
                      "enabled:hover:bg-on-surface-200",
                      "enabled:hover:border-on-surface-400",
                      "enabled:hover:text-on-surface-700"
                    ),
                  ],
                  [
                    "primary",
                    buildClasses(
                      "border",
                      "border-purple",
                      "bg-purple-100",
                      "text-purple",
                      "enabled:hover:bg-purple-200",
                      "enabled:hover:border-purple-600",
                      "enabled:hover:text-purple-600"
                    ),
                  ],
                  [
                    "danger",
                    buildClasses(
                      "border",
                      "border-red",
                      "bg-red-100",
                      "text-red",
                      "enabled:hover:bg-red-200",
                      "enabled:hover:border-red-600",
                      "enabled:hover:text-red-600"
                    ),
                  ],
                ]),
              }),
            ],
            [
              "transparent",
              buildClasses({
                switch: vibe,
                cases: new Map([
                  [
                    "subdued",
                    buildClasses(
                      "text-on-surface-500",
                      "enabled:hover:bg-on-surface-200"
                    ),
                  ],
                  [
                    "ordinary",
                    buildClasses(
                      "text-on-surface-700",
                      "enabled:hover:bg-on-surface-200"
                    ),
                  ],
                  [
                    "primary",
                    buildClasses("text-purple", "enabled:hover:bg-purple-100"),
                  ],
                  [
                    "danger",
                    buildClasses("text-red", "enabled:hover:bg-red-100"),
                  ],
                ]),
              }),
            ],
          ]),
        },
        "flex-shrink-0",
        "block", // we do this because of https://stackoverflow.com/questions/40385945/parent-element-with-height-auto-is-higher-than-highest-child
        "disabled:opacity-50",
        "disabled:z-1" // hack to make disabled buttons (which create their own stacking contexts) not show over things like drop-down menus
      )}
      disabled={disabled}
    >
      <ButtonContent text={text} icon={icon} size={size} />
    </button>
  );
}

function ButtonContent({
  text,
  icon,
  size,
}: {
  text?: string;
  icon?: string;
  size: "sm" | "md" | "lg";
}): JSX.Element {
  if (icon === undefined && text === undefined) {
    return <span></span>;
  }
  if (icon === undefined) {
    return (
      <span className={buildClasses("flex", "items-center", "justify-center")}>
        {text}
      </span>
    );
  }
  if (text === undefined) {
    return (
      <div className={buildClasses("flex", "items-center", "justify-center")}>
        <RyeIcon name={icon}></RyeIcon>
      </div>
    );
  }
  return (
    <div className={buildClasses("flex", "items-center", "justify-center")}>
      <RyeIcon
        name={icon}
        size={size}
        className={buildClasses({
          switch: size,
          cases: new Map([
            ["sm", buildClasses("-ml-1", "mr-1")],
            ["md", buildClasses("-ml-1", "mr-1.5")],
            ["lg", buildClasses("-ml-1", "mr-2")],
          ]),
        })}
      ></RyeIcon>
      <span>{text}</span>
    </div>
  );
}
