import {
  getAuth,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import React, { ReactElement, useContext, useState } from "react";
import google_logo from "../images/google_logo.png";
import { RyeButton } from "../rye/RyeButton";
import { RyeIcon } from "../rye/RyeIcon";
import { RyeInput } from "../rye/RyeInput";
import { buildClasses } from "../utils/buildClasses";
import { createContextWithSetter } from "../utils/ContextUtils";
import { errorToMsg } from "../utils/ErrorToMsg";
import { AuthScreenContext, AuthScreens } from "./AuthScreen";
import { AuthScreenBase } from "./AuthScreenBase";
import { AuthStrategyButton } from "./AuthStrategyButton";
import { ErrorBanner } from "./ErrorBanner";

export enum SignInScreens {
  STRATEGY_SELECTION,
  EMAIL_AND_PASSWORD_ENTRY,
}

export const SignInContext = createContextWithSetter<SignInScreens>(
  SignInScreens.STRATEGY_SELECTION
);

export function SignInScreen(): JSX.Element {
  const [signInScreen, setSignInScreen] = useState(
    SignInScreens.STRATEGY_SELECTION
  );
  const [, setAuthScreen] = useContext(AuthScreenContext);

  return (
    <SignInContext.Provider value={[signInScreen, setSignInScreen]}>
      <AuthScreenBase
        title={"Sign in to Bread"}
        content={<Content />}
        contentGap="sm"
        footer={{
          promptText: "New to Bread?",
          actionText: "Create an account",
          onActionClick: () => setAuthScreen(AuthScreens.CREATE_ACCOUNT_SCREEN),
        }}
        backButtonAction={
          signInScreen === SignInScreens.EMAIL_AND_PASSWORD_ENTRY
            ? () => setSignInScreen(SignInScreens.STRATEGY_SELECTION)
            : null
        }
      />
    </SignInContext.Provider>
  );
}

function Content(): JSX.Element {
  const [signInScreen] = useContext(SignInContext);

  switch (signInScreen) {
    case SignInScreens.STRATEGY_SELECTION:
      return <StrategiesContent />;
    case SignInScreens.EMAIL_AND_PASSWORD_ENTRY:
      return <EmailAndPasswordEntryContent />;
    default:
      return <StrategiesContent />;
  }
}

function StrategiesContent(): JSX.Element {
  const [, setSignInScreen] = useContext(SignInContext);

  return (
    <React.Fragment>
      <AuthStrategyButton
        text="Sign in with Google"
        icon={
          <img
            src={google_logo}
            className={buildClasses("w-5")}
            alt="Google logo"
          />
        }
        onClick={() => submitLogInWithGoogle()}
      />
      <AuthStrategyButton
        text="Sign in with email"
        icon={<RyeIcon name={"mail"} size="md" />}
        onClick={() => setSignInScreen(SignInScreens.EMAIL_AND_PASSWORD_ENTRY)}
      />
    </React.Fragment>
  );
}

function EmailAndPasswordEntryContent(): ReactElement {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [attemptingLogIn, setAttemptingLogIn] = useState(false);

  function handleEmailChanged(text: string) {
    setEmail(text);
  }

  function handlePasswordChanged(text: string) {
    setPassword(text);
  }

  function preventSubmit() {
    return attemptingLogIn || email === "" || password === "";
  }

  async function submit() {
    if (preventSubmit()) return;
    setAttemptingLogIn(true);
    setErrorMessage(null);
    const auth = getAuth();
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      setErrorMessage(errorToMsg(error));
    }
    setAttemptingLogIn(false);
  }

  return (
    <React.Fragment>
      <RyeInput
        width="full"
        placeholderText="Email"
        focusOnInit
        onChange={handleEmailChanged}
        onEnter={submit}
      />
      <RyeInput
        width="full"
        type="password"
        placeholderText="Password"
        onChange={handlePasswordChanged}
        onEnter={submit}
      />
      <RyeButton
        text="Submit"
        vibe="primary"
        variant="filled"
        onClick={submit}
        disabled={preventSubmit()}
      />
      <ErrorBanner text={errorMessage ?? ""} show={errorMessage !== null} />
    </React.Fragment>
  );
}

async function submitLogInWithGoogle() {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  try {
    await signInWithPopup(auth, provider);
  } catch (error) {
    console.log(error);
  }
}
