import React from "react";
import { buildClasses } from "../utils/buildClasses";

export function AuthStrategyButton(props: {
  text: string;
  icon: React.ReactNode;
  onClick: () => void;
}): JSX.Element {
  return (
    <button
      className={buildClasses(
        "w-full",
        "border",
        "border-on-surface-300",
        "rounded-md",
        "px-4",
        "py-2",
        "relative",
        "shadow-sm",
        "hover:bg-on-surface-100",
        "transition-all"
      )}
      onClick={props.onClick}
    >
      <Icon icon={props.icon} />
      {props.text}
    </button>
  );
}

function Icon(props: { icon: React.ReactNode }): JSX.Element {
  return <div className={buildClasses("absolute", "left-4")}>{props.icon}</div>;
}
