import { ReactElement, useEffect, useRef, useState } from "react";
import { buildClasses } from "../utils/buildClasses";
import { keyCodes } from "../utils/KeyCodes";

export function RyeInput({
  startingText = "",
  placeholderText = "",
  type = "",
  size = "md",
  height = "auto",
  width = "md",
  textAlign = "left",
  focusOnInit = false,
  ackFocus = () => {},
  onChange = () => {},
  onEnter = () => {},
}: {
  startingText?: string;
  placeholderText?: string;
  type?: "" | "email" | "password";
  size?: "sm" | "md" | "lg";
  height?: "auto" | "full";
  width?: "sm" | "md" | "lg" | "xl" | "full";
  textAlign?: "left" | "center" | "right";
  focusOnInit?: boolean;
  ackFocus?: () => void;
  onChange?: (text: string) => void;
  onEnter?: (text: string) => void;
}): ReactElement {
  const [text, setText] = useState(startingText);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (focusOnInit) {
      inputRef.current?.select(); // this takes care of focusing too!
    }
  }, [focusOnInit]);

  // Keep the text in sync with any changes to the input text. One far-fetched
  // reason to make sure we do this is in case someone changes the value
  // on a different device.
  useEffect(() => setText(startingText), [startingText]);

  function handleOnKeyDown(e: React.KeyboardEvent<HTMLInputElement>): void {
    if (e.repeat) {
      return;
    }
    switch (e.code) {
      case keyCodes.ESC:
        inputRef.current?.blur();
        break;
      case keyCodes.ENTER:
        inputRef.current?.blur();
        onEnter(text);
        break;
    }
  }

  function handleInputChanged(e: React.ChangeEvent<HTMLInputElement>) {
    setText(e.target.value);
    onChange(e.target.value);
  }

  return (
    <input
      ref={inputRef}
      type={type}
      placeholder={placeholderText}
      className={buildClasses(
        {
          switch: size,
          cases: new Map([
            ["sm", buildClasses("text-sm")],
            ["md", buildClasses("text-md")],
            ["lg", buildClasses("text-lg")],
          ]),
        },
        {
          switch: height,
          cases: new Map([
            [
              "auto",
              buildClasses({
                switch: size,
                cases: new Map([
                  ["sm", buildClasses("h-7")],
                  ["md", buildClasses("h-9")],
                  ["lg", buildClasses("h-11")],
                ]),
              }),
            ],
            ["full", buildClasses("h-full")],
          ]),
        },
        {
          switch: width,
          cases: new Map([
            ["sm", "w-44"],
            ["md", "w-56"],
            ["lg", "w-72"],
            ["xl", "w-96"],
            ["full", "w-full"],
          ]),
        },
        {
          switch: textAlign,
          cases: new Map([
            ["left", "text-left"],
            ["center", "text-center"],
            ["right", "text-right"],
          ]),
        },
        "px-3",
        "bg-transparent",
        "border",
        "border-on-surface-200",
        "rounded-md",
        "hover:border-on-surface-300",
        "focus:border-purple",
        "focus:shadow-glow",
        "focus:shadow-purple-100",
        "outline-none",
        "transition-all"
      )}
      value={text}
      onChange={(e) => handleInputChanged(e)}
      onKeyDown={(e) => handleOnKeyDown(e)}
    />
  );
}
