import { ReactElement } from "react";
import { buildClasses } from "../utils/buildClasses";

export function ErrorBanner(props: {
  text: string;
  show: boolean;
}): ReactElement | null {
  if (!props.show) {
    return null;
  }
  return (
    <div
      className={buildClasses(
        "p-3",
        "bg-red-50",
        "text-red",
        "text-center",
        "rounded-md",
        "border",
        "border-red"
      )}
    >
      {props.text}
    </div>
  );
}
