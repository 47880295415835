import { useEffect, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { createContextWithSetter } from "../utils/ContextUtils";
import { CreateAccountScreen } from "./CreateAccountScreen";
import { SignInScreen } from "./SignInScreen";

export enum AuthScreens {
  CREATE_ACCOUNT_SCREEN,
  SIGN_IN_SCREEN,
  // LoginOptions,
  // EmailLogin,
  // CreateAccountOptions,
  // EmailCreateAccount,
  // AccessCode,
}

export const AuthScreenContext = createContextWithSetter<AuthScreens>(
  AuthScreens.SIGN_IN_SCREEN
);

function getAuthScreen(authScreenState: AuthScreens): JSX.Element {
  switch (authScreenState) {
    case AuthScreens.CREATE_ACCOUNT_SCREEN:
      return <CreateAccountScreen></CreateAccountScreen>;
    case AuthScreens.SIGN_IN_SCREEN:
      return <SignInScreen></SignInScreen>;
    default:
      return <SignInScreen></SignInScreen>;
  }
}

function locationToAuthScreen(path: string): AuthScreens {
  const urlMatch = matchPath("/auth/:route", path);

  if (urlMatch?.params.route === "signup") {
    return AuthScreens.CREATE_ACCOUNT_SCREEN;
  }
  return AuthScreens.SIGN_IN_SCREEN;
}

export function AuthScreen(): JSX.Element {
  const [authScreen, setAuthScreen] = useState(AuthScreens.SIGN_IN_SCREEN);

  const location = useLocation();

  useEffect(() => {
    const newAuthScreen = locationToAuthScreen(location.pathname);
    setAuthScreen(newAuthScreen);
  }, [location]);

  return (
    <AuthScreenContext.Provider value={[authScreen, setAuthScreen]}>
      {getAuthScreen(authScreen)}
    </AuthScreenContext.Provider>
  );
}
