import React, { ReactElement, useContext, useState } from "react";
import { AuthScreenBase } from "./AuthScreenBase";
import { buildClasses } from "../utils/buildClasses";
import google_logo from "../images/google_logo.png";
import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { RyeIcon } from "../rye/RyeIcon";
import { AuthStrategyButton } from "./AuthStrategyButton";
import { createContextWithSetter } from "../utils/ContextUtils";
import { AuthScreenContext, AuthScreens } from "./AuthScreen";
import { RyeInput } from "../rye/RyeInput";
import { RyeButton } from "../rye/RyeButton";
import { ErrorBanner } from "./ErrorBanner";
import { firestoreCreateUser } from "../firebaseio/firestoreIo";
import { errorToMsg } from "../utils/ErrorToMsg";

export enum CreateAccountScreens {
  BETA_ACCESS_CODE,
  STRATEGY_SELECTION,
  EMAIL_AND_PASSWORD_ENTRY,
}

export const CreateAccountContext =
  createContextWithSetter<CreateAccountScreens>(
    CreateAccountScreens.BETA_ACCESS_CODE
  );

export function CreateAccountScreen(): JSX.Element {
  const [createAccountScreen, setCreateAccountScreen] = useState(
    CreateAccountScreens.BETA_ACCESS_CODE
  );
  const [, setAuthScreen] = useContext(AuthScreenContext);

  return (
    <CreateAccountContext.Provider
      value={[createAccountScreen, setCreateAccountScreen]}
    >
      <AuthScreenBase
        title={"Create a new Bread account"}
        content={<Content />}
        contentGap="sm"
        footer={{
          promptText: "Already have an account?",
          actionText: "Sign in",
          onActionClick: () => setAuthScreen(AuthScreens.SIGN_IN_SCREEN),
        }}
        backButtonAction={
          createAccountScreen === CreateAccountScreens.EMAIL_AND_PASSWORD_ENTRY
            ? () =>
                setCreateAccountScreen(CreateAccountScreens.STRATEGY_SELECTION)
            : null
        }
      />
    </CreateAccountContext.Provider>
  );
}

function Content(): JSX.Element {
  const [createAccountScreen] = useContext(CreateAccountContext);

  switch (createAccountScreen) {
    case CreateAccountScreens.BETA_ACCESS_CODE:
      return <BetaAccessCodeContent />;
    case CreateAccountScreens.STRATEGY_SELECTION:
      return <StrategiesContent />;
    case CreateAccountScreens.EMAIL_AND_PASSWORD_ENTRY:
      return <EmailAndPasswordEntryContent />;
    default:
      return <BetaAccessCodeContent />;
  }
}

function BetaAccessCodeContent(): JSX.Element {
  const [accessCodeInputValue, setAccessCodeInputValue] = useState("");
  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [, setCreateAccountScreen] = useContext(CreateAccountContext);

  const ACCESS_CODE = "SOURDOUGH";

  function handleInputChanged(text: string) {
    setAccessCodeInputValue(text);
  }

  function preventSubmit() {
    return accessCodeInputValue === "";
  }

  function submit(accessCode: string) {
    if (preventSubmit()) return;

    if (accessCode.toUpperCase() === ACCESS_CODE) {
      setCreateAccountScreen(CreateAccountScreens.STRATEGY_SELECTION);
    } else {
      setShowErrorBanner(true);
    }
  }

  return (
    <React.Fragment>
      <div className={buildClasses("text-center", "text-on-surface-500")}>
        Bread is currently in private beta. To join, please enter your access
        code below.
      </div>
      <RyeInput
        width="full"
        textAlign="center"
        placeholderText="Beta access code"
        focusOnInit
        onChange={handleInputChanged}
        onEnter={submit}
      />
      <RyeButton
        text="Submit"
        vibe="primary"
        variant="filled"
        onClick={() => submit(accessCodeInputValue)}
        disabled={preventSubmit()}
      />
      <ErrorBanner
        text={"Incorrect access code. Please try again."}
        show={showErrorBanner}
      />
    </React.Fragment>
  );
}

function StrategiesContent(): JSX.Element {
  const [, setCreateAccountScreen] = useContext(CreateAccountContext);

  return (
    <React.Fragment>
      <AuthStrategyButton
        text="Create account with Google"
        icon={
          <img
            src={google_logo}
            className={buildClasses("w-5")}
            alt="Google logo"
          />
        }
        onClick={() => submitLogInWithGoogle()}
      />
      <AuthStrategyButton
        text="Create account with email"
        icon={<RyeIcon name={"mail"} size="md" />}
        onClick={() =>
          setCreateAccountScreen(CreateAccountScreens.EMAIL_AND_PASSWORD_ENTRY)
        }
      />
    </React.Fragment>
  );
}

function EmailAndPasswordEntryContent(): ReactElement {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [attemptingAccountCreation, setAttemptingAccountCreation] =
    useState(false);

  function handleEmailChanged(text: string) {
    setEmail(text);
  }

  function handlePasswordChanged(text: string) {
    setPassword(text);
  }

  function handlePasswordConfirmationChanged(text: string) {
    setPasswordConfirmation(text);
  }

  function preventSubmit() {
    return email === "" || password === "" || passwordConfirmation === "";
  }

  async function submit() {
    if (preventSubmit()) return;

    setAttemptingAccountCreation(true);
    if (password !== passwordConfirmation) {
      setErrorMessage(
        "Password fields don't match. Please ensure they match and then try again."
      );
      setAttemptingAccountCreation(false);
      return;
    }

    const auth = getAuth();

    try {
      let userCreds = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      await firestoreCreateUser(userCreds.user.uid);
    } catch (error) {
      setErrorMessage(errorToMsg(error));
    }
    setAttemptingAccountCreation(false);
  }

  return (
    <React.Fragment>
      <RyeInput
        width="full"
        placeholderText="Email"
        focusOnInit
        onChange={handleEmailChanged}
        onEnter={submit}
      />
      <RyeInput
        width="full"
        type="password"
        placeholderText="Password"
        onChange={handlePasswordChanged}
        onEnter={submit}
      />
      <RyeInput
        width="full"
        type="password"
        placeholderText="Confirm Password"
        onChange={handlePasswordConfirmationChanged}
        onEnter={submit}
      />
      <RyeButton
        text="Submit"
        vibe="primary"
        variant="filled"
        onClick={submit}
        disabled={attemptingAccountCreation || preventSubmit()}
      />
      <ErrorBanner text={errorMessage ?? ""} show={errorMessage !== null} />
    </React.Fragment>
  );
}

async function submitLogInWithGoogle() {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  try {
    await signInWithPopup(auth, provider);
  } catch (error) {
    console.log(error);
  }
}
