import { buildClasses } from "../utils/buildClasses";
import bread_logo from "../images/bread_logo.png";
import { RyeButton } from "../rye/RyeButton";
import React, { ReactElement } from "react";

interface Footer {
  promptText: string;
  actionText: string;
  onActionClick: () => void;
}

export function AuthScreenBase({
  title,
  content,
  footer,
  backButtonAction = null,
  contentGap = "lg",
}: {
  title: string;
  content: React.ReactNode;
  footer: Footer;
  backButtonAction?: (() => void) | null;
  contentGap?: "sm" | "lg";
}): JSX.Element {
  return (
    <div className={buildClasses("h-screen")}>
      <div
        className={buildClasses(
          "flex",
          "flex-col",
          "items-center",
          "h-full",
          "bg-base"
        )}
      >
        <div className={buildClasses("w-full", "h-[13%]")} />
        <div className={buildClasses("p-10")}>
          <BreadLogo />
        </div>
        <div className={buildClasses("pb-52")}>
          <AuthContentCard
            title={title}
            content={content}
            contentGap={contentGap}
            footer={footer}
            backButtonAction={backButtonAction}
          />
        </div>
      </div>
    </div>
  );
}

function BreadLogo(): JSX.Element {
  return (
    <img
      src={bread_logo}
      className={buildClasses("w-24")}
      alt="Bread logo"
    ></img>
  );
}

function AuthContentCard(props: {
  title: string;
  content: React.ReactNode;
  contentGap: "sm" | "lg";
  footer: Footer;
  backButtonAction: (() => void) | null;
}): JSX.Element {
  return (
    <div
      className={buildClasses(
        "flex",
        "flex-col",
        "items-center",
        "justify-center",
        "bg-surface",
        "shadow-sm",
        "w-[26rem]",
        "rounded-xl",
        "gap-10"
      )}
    >
      <CardTitle
        title={props.title}
        backButtonAction={props.backButtonAction}
      />
      <CardContent content={props.content} contentGap={props.contentGap} />
      <CardFooter footer={props.footer} />
    </div>
  );
}

function CardTitle(props: {
  title: string;
  backButtonAction: (() => void) | null;
}): JSX.Element {
  return (
    <React.Fragment>
      <div className={buildClasses("p-10", "pb-0", "w-full")}>
        <div
          className={buildClasses(
            "relative",
            "text-xl",
            "font-medium",
            "w-full",
            "flex",
            "justify-center"
          )}
        >
          <CardBackButton action={props.backButtonAction} />
          {props.title}
        </div>
      </div>
    </React.Fragment>
  );
}

function CardBackButton(props: {
  action: (() => void) | null;
}): ReactElement | null {
  if (props.action === null) {
    return null; // don't show the back button at all
  }
  return (
    <div className={buildClasses("absolute", "top-0", "left-0")}>
      <RyeButton
        size="sm"
        icon="arrow_back"
        variant="transparent"
        vibe="subdued"
        onClick={props.action}
      />
    </div>
  );
}

function CardContent(props: {
  content: React.ReactNode;
  contentGap: "sm" | "lg";
}): JSX.Element {
  return (
    <div
      className={buildClasses(
        {
          switch: props.contentGap,
          cases: new Map([
            ["sm", buildClasses("gap-5")],
            ["lg", buildClasses("gap-10")],
          ]),
        },
        "w-full",
        "flex",
        "flex-col",
        "px-10"
      )}
    >
      {props.content}
    </div>
  );
}

function CardFooter(props: { footer: Footer }): JSX.Element {
  return (
    <div className={buildClasses("w-full", "p-1")}>
      <div
        className={buildClasses(
          "flex",
          "justify-center",
          "bg-on-surface-100",
          "rounded-[0.6rem]",
          "w-full",
          "p-4",
          "text-[0.8rem]",
          "text-on-surface-500"
        )}
      >
        {props.footer.promptText}
        <span
          className={buildClasses(
            "text-[0.8rem]",
            "text-purple",
            "ml-1",
            "cursor-pointer",
            "hover:text-purple-800"
          )}
          onClick={props.footer.onActionClick}
        >
          {props.footer.actionText}
        </span>
      </div>
    </div>
  );
}
